const prod = {
  SERVER_URL: 'https://riabilita.01rabbit.it/api',
  SAMPLE_DATA: false,
  APP_NAME: 'Riabilita'
}

const dev = {
  SERVER_URL: 'http://localhost:8080/api',
  SAMPLE_DATA: false,
  APP_NAME: 'Riabilita dev.'
}

export const config = process.env.NODE_ENV === 'production' ? prod : dev
