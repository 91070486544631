
import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { Controller } from 'react-hook-form'

const ZUHFSelect = forwardRef(({ name, label, register, defaultValue, required, rules, error, control, children }, ref) => (

  <Controller
    name={name}
    rules={rules}
    control={control}
    render={({ field }) => (
      <FormControl
        fullWidth
        required={required}
        defaultValue={defaultValue}
        error={error}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          {...field}
          label={label}
        >
          {children}
        </Select>
      </FormControl>
    )}
  />

))

// Setting default values for the props
ZUHFSelect.defaultProps = {
  required: false,
  error: false
}

// Typechecking props for the ZUTitleForm
ZUHFSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.node
}

export default ZUHFSelect
